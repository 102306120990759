import React from 'react'

export const LoginContext = React.createContext()
export function MoveLoginProvider({ children }) {


    const [move, setMove] = React.useState(false)
    const [coms, setComs] = React.useState()
    console.log("coms", coms)
    return (
        <LoginContext.Provider value={{ move, setMove, coms, setComs }}>
            {children}
        </LoginContext.Provider>
    )
}
export function useMover() {
    return React.useContext(LoginContext)
}
